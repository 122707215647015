import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {AdminStoreStateProps} from "@/models";


export const useAdminStore = defineStore("admin", {
  state: (): AdminStoreStateProps => ({
    items: [],
    query: {
      page: 1,
      perPage: 50,
      name: ''
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        admin_id: 0,
        id: '',
        name: '',
        password: '',
        password_verified: '',
        access_flag: false
      };
      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },
    async onList(): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/admin`,
        onError: false,
        params: this.query,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.items = apiData.admin_items;
          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },

    async onCreate() {
      this.formSaving = true;
      return apiRequest.post({
        url: `/api/admin`,
        data: this.form
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {

          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGet(adminId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/admin/${adminId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.form = apiData.admin;
          this.formOrigin = cloneDeep(apiData.admin);

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.form = null;
          this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },

    async onUpdate(adminId: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/admin/${adminId}`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onUpdateAccess(adminId: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/admin/${adminId}/access`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },
    async onDelete(adminId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/admin/${adminId}`,
      })
        .then((data) => {
          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })

    }

  }

})
