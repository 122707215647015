import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import { RewardStoreStateProps} from "@/models";


export const useRewardStore = defineStore("reward", {
  state: (): RewardStoreStateProps => ({
    items: [],
    itemsMap: {},
    query: {
      page: 1,
      perPage: 50,
      name: ''
    },
    pagination: {
      count: 0,
      next_flag: false,
      page: 1,
      page_numbers: [1],
      per_page: 1,
      prev_flag: false
    },

    count: 0,
    listLoading: false,

    form: null,
    formOrigin: null,
    formLoading: false,
    formSaving: false,

    options: [],
    missionRewardOptions: []
  }),
  getters: {},
  actions: {
    initForm() {
      this.form = {
        reward_id: 0,
        reward_set_id: 0,
        name: '',
        reward_media: [
          {
            file_id: 0,
            type: '',
            url: ''
          }
        ],
        type: null,
        description: '',
      };

      this.formOrigin = cloneDeep(this.form)
    },
    resetForm() {
      this.form = cloneDeep(this.formOrigin);
    },


    async onList(rewardSetId: number ): Promise<void> {
      this.itemsMap[Number(rewardSetId)] = {
        items: [],
        loading: true
      }
      this.listLoading = true
      return apiRequest.get({
        url: `/api/reward-set/${rewardSetId}/reward`,
        onError: false,
        params: this.query,
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;

          this.itemsMap[Number(rewardSetId)].items = apiData.reward_items

          this.pagination = apiData.pagination;

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.itemsMap[Number(rewardSetId)].loading = false;

          this.listLoading = false;
        })
    },

    async getOptions(rewardSetId: number): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/reward-set/${rewardSetId}/reward/options/type/1`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.options = apiData.reward_options;
          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },

    async getMissionRewardOptions(gameId: number, missionId: number): Promise<void> {
      this.listLoading = true
      return apiRequest.get({
        url: `/api/game/${gameId}/reward/options/distinct`,
        onError: false,
        params: {
          missionId: missionId
        }
      })
        .then((data) => {
          //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
          const {apiData} = data;
          this.missionRewardOptions = apiData.reward_options;
          return Promise.resolve(apiData);
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .finally(() => {
          this.listLoading = false;
        })
    },


    async onCreate(rewardSetId: number) {
      this.formSaving = true;
      return apiRequest.post({
        url: `/api/reward-set/${rewardSetId}/reward`,
        data: this.form
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onGet(rewardSetId: number, rewardId: number): Promise<void> {
      this.formLoading = true
      return apiRequest.get({
        url: `/api/reward-set/${rewardSetId}/reward/${rewardId}`,
        onError: false,
      })
        .then((data) => {
          const {apiData} = data;
          this.form = apiData.reward;
          this.formOrigin = cloneDeep(apiData.reward);

          return Promise.resolve(apiData);
        })
        .catch((error) => {
          this.form = null;
          this.formOrigin = null;

          return Promise.reject(error);
        })
        .finally(() => {
          this.formLoading = false;
        })
    },

    async onUpdate(rewardSetId: number, rewardId: number) {
      this.formSaving = true;
      return apiRequest.put({
        url: `/api/reward-set/${rewardSetId}/reward/${rewardId}`,
        data: this.form,
      })
        .then((data) => {
          this.formOrigin = cloneDeep(this.form);

          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })
    },

    async onDelete(rewardSetId: number, rewardId: number) {
      this.formSaving = true;
      return apiRequest.delete({
        url: `/api/reward-set/${rewardSetId}/reward/${rewardId}`,
      })
        .then((data) => {
          return Promise.resolve(data.apiData);
        })
        .catch((error) => {
          return Promise.reject(error.apiData);
        })
        .finally(() => {
          this.formSaving = false;
        })

    }

  }

})
